import * as Radix from "@radix-ui/themes";
import {
  getSize,
  ResponsiveTextStyle,
  TextStyle,
} from "@/design-system/components/text/TextStyle";

type RadixHeadingProps = React.ComponentProps<typeof Radix.Heading>;

type HeadingOwnProps = {
  textStyle: TextStyle | ResponsiveTextStyle;
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

// We cannot use asChild here as it does not know what signature to use for the "as" prop.
interface HeadingProps
  extends Omit<RadixHeadingProps, "size" | "weight" | "asChild" | "as">,
    HeadingOwnProps {}

function HeadingV2({ textStyle, ...rest }: HeadingProps) {
  return (
    <Radix.Heading {...rest} {...getStyledOverrides({ textStyle, ...rest })} />
  );
}

function getStyledOverrides({ textStyle }: HeadingOwnProps): {
  size: RadixHeadingProps["size"];
  weight: RadixHeadingProps["weight"];
} {
  let size: RadixHeadingProps["size"];
  if (textStyle && typeof textStyle !== "object") {
    size = {
      initial: getSize(textStyle, "initial"),
      sm: getSize(textStyle, "sm"),
      md: getSize(textStyle, "md"),
      lg: getSize(textStyle, "lg"),
    };
  } else {
    // Apply responsive sizes
    size = {
      initial: getSize(textStyle.initial, "initial"),
      sm: getSize(textStyle.sm, "sm"),
      md: getSize(textStyle.md, "md"),
      lg: getSize(textStyle.lg, "lg"),
    };
  }

  return { size, weight: "regular" };
}
export { HeadingV2 };
