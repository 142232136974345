import * as Radix from "@radix-ui/themes";
import { ContainerProps as RadixContainerProps } from "@radix-ui/themes/dist/cjs/components/container";

type ContainerStyle = "wide" | "normal";

type ContainerOwnProps = {
  containerStyle?: ContainerStyle;
};

interface ContainerProps extends RadixContainerProps, ContainerOwnProps {}

function LayoutContainerV2({ containerStyle, ...rest }: ContainerProps) {
  return (
    <Radix.Container
      size={"4"}
      {...rest}
      {...getStyledOverrides(containerStyle || "normal")}
    />
  );
}

function getStyledOverrides(containerStyle: ContainerStyle) {
  let px: RadixContainerProps["px"];
  if (containerStyle === "wide") {
    px = { initial: "3", sm: "9" };
  } else if (containerStyle === "normal") {
    px = { initial: "5", sm: "9" };
  } else {
    throw new Error(
      `Illegal "containerStyle" parameter for LayoutContainerV2:'${containerStyle}'`,
    );
  }
  return { px };
}

export { LayoutContainerV2 };
